import React, { useState, useRef, useCallback } from 'react';
import Api from "../../Api";
import Modal from "rsuite/Modal";
import Form from "rsuite/Form";
import Button from "rsuite/Button";
import Schema from "rsuite/Schema";
import ButtonSubmit from "../../components/form/ButtonSubmit";
import fileSaver from "file-saver/dist/FileSaver";

const ImportStorage = ({ date, callback = () => {} }) => {
    const [show, setShow] = useState(false);
    const [error, setError] = useState('');
    const [file, setFile] = useState(null); // Отдельно отслеживаем файл
    const formRef = useRef();

    const submit = (e, d) => {
        console.log(e, d)
        setError('');

        // Проверка допустимых форматов файлов
        const allowedFormats = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
            'text/xml', // .xml
            'application/xml', // .xml
            'text/csv', // .csv
            'application/vnd.ms-excel' // .csv (старый формат Excel)
        ];

        if (!file || !allowedFormats.includes(file.type)) {
            setError('Please upload a valid .xlsx, .xml, or .csv file');
            return;
        }

        let data = new FormData();
        data.append('file', file);
        data.append('date', date);

        Api.post('storages/import', data)
            .then(r => {
                callback(r);
                setShow(false);
                setFile(null)
            })
            .catch(e => {
                console.log(e)
                formRef.check = false;
                setError(e.message);
            });
    };

    const model = Schema.Model({
        file: Schema.Types.ObjectType().isRequired('Please upload a .xlsx, .xml, or .csv file.')
    });

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0]; // Получаем первый файл из списка
        setFile(selectedFile); // Устанавливаем его в состояние
    };

    const [isExport, setIsExport] = useState(false)
    const handleExport = useCallback(() => {
        setIsExport(true)
        Api.get(`storages/export-for-import?date=${date}`)
            .then(r => {
                const regex = /[^/]*$/gm;
                let m = regex.exec(r)
                fileSaver.saveAs(r, m[0]);
                setIsExport(false)
            })
    }, [date])

    return (
        <>
            <Button
                onClick={() => setShow(true)}
                color="orange"
                size="sm"
                className="btn btn-sm btn-primary ms-2"
            >
                Import
            </Button>
            <Modal open={show} onClose={() => setShow(false)} size="xs">
                <Modal.Header closeButton>
                    <Modal.Title>Import Storage</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button
                        appearance="primary"
                        size="sm"
                        loading={isExport}
                        className="btn btn-sm btn-primary"
                        onClick={handleExport}
                    >
                        Export File for Import
                    </Button>
                    <Form
                        model={model}
                        fluid
                        onSubmit={submit}
                        ref={formRef}
                    >
                        <Form.Group className="mb-3" controlId="file">
                            <Form.ControlLabel>File (.xlsx, .xml, .csv)</Form.ControlLabel>
                            <input
                                name="file"
                                type="file"
                                accept=".xlsx, .xml, .csv"
                                onChange={handleFileChange} // Обрабатываем изменение файла
                            />
                            <div>
                                Date for update: { date }
                            </div>
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <ButtonSubmit formRef={formRef}>
                                Submit
                            </ButtonSubmit>
                        </div>
                        {error && <div className="text-danger w-100 text-center mt-2">{error}</div>}
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ImportStorage;
